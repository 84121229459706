import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../Context';
import homeEs from '../../assets/data/homeSlider/es/index.json';
import homeEn from '../../assets/data/homeSlider/en/index.json';
import HomeLoading from '../../components/homeLoading';
import MetaTags from '../../components/metaTags';
import MainVideo from '../../components/mainVideo';
import HomeTheMostViewed from '../../components/homeTheMostViewed';
import HomePromotion from '../../components/homePromotion';
import HomeEvents from '../../components/homeEvents';
import HomeRecentArticles from '../../components/homeRecentArticles';

const Home = () => {
  const { isLanguage } = useContext(Context);
  const [language, setLanguage] = useState(homeEs);

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(homeEs)
    : isLanguage === 'EN' ? setLanguage(homeEn)
    : setLanguage(homeEs)
  }, [isLanguage]);

  const [loading, setLoading] = useState(false);

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(!loading);
    }, 1500);
  }, []);

  const metaInfo = {
    'ES': {
      title: 'México: Destinos Turísticos y Experiencias Inolvidables',
      description: 'Descubre los mejores destinos turísticos, culturas y experiencias que México tiene para ofrecer. Planifica tu viaje con Visit México.'
    },
    'EN': {
      title: 'Mexico: Tourist Destinations & Unforgettable Experiences',
      description: 'Discover top tourist destinations, cultures, and experiences Mexico offers. Plan your trip with Visit Mexico.'
    }
  };

  return (
    <>
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      {loading ?
        <>
        <MainVideo />
        <HomeTheMostViewed data={language} />
        <HomeEvents data={language}/>
        <HomeRecentArticles data={language}/>
        <HomePromotion data={language}/>
        {/* <HomeMainEvents /> */}
        </>
        :
        <>
          <HomeLoading />
        </>
      }
    </>
  );
};

export default Home;
