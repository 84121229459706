import React, { useState, useEffect, useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link,  } from 'react-router-dom'; // Importa Link
import { useParams } from 'react-router-dom';
import { Context } from '../../Context'; // Importa el contexto si es necesario

import postEs from '../../assets/data/newBlog/es/index.json';
import postEn from '../../assets/data/newBlog/en/index.json';
import postFr from '../../assets/data/newBlog/fr/index.json';
import postDe from '../../assets/data/newBlog/de/index.json';
import postPt from '../../assets/data/newBlog/pt/index.json';
import postIt from '../../assets/data/newBlog/it/index.json';
import postJp from '../../assets/data/newBlog/jp/index.json';
import postCn from '../../assets/data/newBlog/cn/index.json';
import './styles.css';
import MetaTags from '../metaTags';

const CategoryArticles = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(postEs);
  const { category } = useParams(); // Obtener el parámetro de la URL para la categoría

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(postEs)
    : isLanguage === 'EN' ? setLanguage(postEn)
    : isLanguage === 'FR' ? setLanguage(postFr)
    : isLanguage === 'DE' ? setLanguage(postDe)
    : isLanguage === 'PT' ? setLanguage(postPt)
    : isLanguage === 'IT' ? setLanguage(postIt)
    : isLanguage === 'JP' ? setLanguage(postJp)
    : isLanguage === 'CN' ? setLanguage(postCn)
    : setLanguage(postEs)
  }, [isLanguage]);

  // if (!categoryInfo) {
  //   return <Navigate to="/blog" />; // Redirigir a la página principal del blog si no se encuentra la categoría
  // }
  // Filtrar los datos para obtener solo los artículos de la categoría especificada
  const categoryData = language.posts.filter((post) => post.categoriaSlug === category);
  //  Función para reemplazar guiones con espacios
  const formatCategory = (category) => {
    return category.replace(/-/g, ' ');
  };

  const metaInfo = {
    'ES': {
      title: 'Blog de Visit México: Descubre lo Mejor de México',
      description: 'Explora el blog de Visit México y descubre artículos fascinantes sobre destinos, cultura, gastronomía y más. Vive la experiencia única de México'
    },
    'EN': {
      title: 'Visit Mexico Blog: Discover the Best of Mexico',
      description: 'Explore Visit Mexico\'s blog and discover fascinating articles about destinations, culture, gastronomy, and more. Experience the unique beauty of Mexico'
    },
    'FR': {
      title: 'Blog Visit Mexico: Découvrez le Meilleur du Mexique',
      description: 'Explorez le blog de Visit Mexico et découvrez des articles fascinants sur les destinations, la culture, la gastronomie et plus encore. Vivez l\'expérience unique du Mexique.">'
    },
    'DE': {
      title: 'Visit Mexico Blog: Entdecken Sie das Beste von Mexiko',
      description: 'Erkunden Sie den Blog von Visit Mexico und entdecken Sie faszinierende Artikel über Reiseziele, Kultur, Gastronomie und mehr. Erleben Sie die einzigartige Schönheit Mexikos'
    },
    'PT': {
      title: 'Blog Visit Mexico: Descubra o Melhor do México',
      description: 'Explore o blog do Visit Mexico e descubra artigos fascinantes sobre destinos, cultura, gastronomia e muito mais. Viva a experiência única do México'
    },
    'IT': {
      title: 'Blog Visit Mexico: Scopri il Meglio del Messico',
      description: 'Esplora il blog di Visit Mexico e scopri articoli affascinanti su destinazioni, cultura, gastronomia e altro ancora. Vivi l\'esperienza unica del Messico'
    },
    'JP': {
      title: 'Visit Mexico ブログ: メキシコの魅力を発見しよう',
      description: 'Visit Mexicoのブログを探検して、旅先、文化、グルメなどについての魅力的な記事を発見しよう。メキシコならではの絶景を体験しよう。'
    },
    'CN': {
      title: 'Visit Mexico 博客: 发现墨西哥的精华',
      description: '探索Visit Mexico的博客，发现关于目的地、文化、美食等精彩文章。体验墨西哥独特的美丽。'
    }
  }

  // Renderizar los artículos de la categoría
  return (
    <div className="category-container">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <h1>{ language.labelCategoryArticles } {formatCategory(category)}</h1>
      {categoryData.length > 0 ? (
        categoryData.map((post) => (
          <div className="middle-content-post" key={post.id}>
                        <div className="middle-content-post-left">
                            <LazyLoadImage src={require(`../../assets/img/${post.urlImg}`)} />
                        </div>
                        <div className="middle-content-post-right">
                            <spam className="badge">{post.categoriaLabel}</spam>
                            <h2>{post.name}</h2>
                            <p>{post.post[0]?.content.substring(0, 100)}...</p>
                            <Link to={`/blog/post/${post.id}`}>{post.postReadmoreButton}</Link>
                        </div>
                    </div>
        ))
      ) : (
        <p className='no-articles'>No hay artículos disponibles en esta categoría.</p>
      )}
      <div className="back-to-blog-container">
        <Link to="/blog" className="back-to-blog-link">{language.labelCategoryArticlesBack}</Link>
      </div>
    </div>
  );
};

export default CategoryArticles;
