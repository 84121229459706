import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import videosEs from '../../assets/data/videos/es/index.json';
import videosEn from '../../assets/data/videos/en/index.json';
import VideosVideo from '../../components/videosVideo';
import VideosTitle from '../../components/videosTitle';
import VideosSlider from '../../components/videosSlider';
import VideosSliderGallery from '../../components/videosSliderGallery';
import VideosTraveler from '../../components/videosTraveler';
import VideosGallery from '../../components/videosGallery';
import VideosBottom from '../../components/videosBottom';
import './styles.css';
import VideosSlider2 from '../../components/videosSlider2';
import MetaTags from '../../components/metaTags';

const Videos = () => {
	const { isLanguage } = useContext(Context);
	const [language, setLanguage] = useState(videosEs);
	// const location = useLocation();
	// const stateId = location?.pathname?.split('/')[2];
	// const getStateInfo = language?.experiences?.filter(video => video.id === videoId);
	// const data = getStateInfo?.[0];

	window?.scroll({
		top: 0
	});

	useEffect(() => {
		isLanguage === 'ES' ? setLanguage(videosEs)
		: isLanguage === 'EN' ? setLanguage(videosEn)
		: setLanguage(videosEs)
	}, [isLanguage]);

	const metaInfo = {
		'ES': {
			title: 'Videos de México: Descubre su Belleza y Cultura',
			description: 'Explora videos de México que muestran su belleza natural, cultura vibrante y tradiciones únicas. Vive la experiencia visual de México con Visit México.'
		},
		'EN': {
			title: 'Mexico Videos: Discover Its Beauty and Culture',
			description: 'Explore videos showcasing Mexico\'s natural beauty, vibrant culture, and unique traditions. Experience Mexico visually with Visit Mexico.'
		},
		'FR': {
			title: 'Vidéos du Mexique: Découvrez sa Beauté et sa Culture',
			description: 'Explorez des vidéos qui montrent la beauté naturelle, la culture vibrante et les traditions uniques du Mexique. Vivez l\'expérience visuelle du Mexique avec Visit Mexique.'
		},
		'DE': {
			title: 'Mexiko Videos: Entdecken Sie seine Schönheit und Kultur',
			description: 'Entdecken Sie Videos, die die natürliche Schönheit, die lebendige Kultur und die einzigartigen Traditionen Mexikos zeigen. Erleben Sie Mexiko visuell mit Visit Mexiko.'
		},
		'PT': {
			title: 'Vídeos do México: Descubra sua Beleza e Cultura',
			description: 'Explore vídeos mostrando a beleza natural, cultura vibrante e tradições únicas do México. Experimente visualmente o México com Visit México.'
		},
		'IT': {
			title: 'Video del Messico: Scopri la sua Bellezza e Cultura',
			description: 'Esplora video che mostrano la bellezza naturale, la cultura vibrante e le tradizioni uniche del Messico. Vivi l\'esperienza visiva del Messico con Visit Messico.'
		},
		'JP': {
			title: 'メキシコのビデオ: 美しさと文化を発見する',
			description: 'メキシコの自然美、活気ある文化、ユニークな伝統を紹介するビデオを探索してください。Visit Mexicoでメキシコのビジュアル体験をお楽しみください。'
		},
		'CN': {
			title: '墨西哥视频: 发现其美丽与文化',
			description: '探索展示墨西哥自然美、活力文化和独特传统的视频。通过Visit Mexico视觉体验墨西哥。'
		}
	};

	return (
		<div className="main-content">
			<MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
			<VideosVideo data={language} />
			<LazyLoadComponent>
				<VideosTitle data={language} />
				<VideosSlider data={language} />
				<VideosSliderGallery data={language} />
				<VideosTraveler data={language} />
				<VideosSlider2 data={language} />
				<VideosGallery data={language} />
				<VideosBottom data={language} />
			</LazyLoadComponent>
		</div>
	);
};

export default Videos;
