import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import winesEs from '../../assets/data/wines/es/index.json';
import winesEn from '../../assets/data/wines/en/index.json';
import WinesSlider from '../../components/winesSlider';
import WinesDescription from '../../components/winesDescription';
import WinesCards from '../../components/winesCards';
import './styles.css';
import MetaTags from '../../components/metaTags';

const Wines = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(winesEs);

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(winesEs)
    : isLanguage === 'EN' ? setLanguage(winesEn)   
    : setLanguage(winesEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Explora Rutas del Vino en México - VisitMexico',
      description: 'Descubre las mejores rutas del vino en México. Disfruta de degustaciones, paisajes y cultura enoturística. ¡Planifica tu visita ahora!'
    },
    'EN': {
      title: 'Explore Mexico\'s Wine Routes - VisitMexico',
      description: 'Discover the best wine routes in Mexico. Enjoy tastings, landscapes, and wine culture. Plan your visit now!'
    },
    'FR': {
      title: 'Explorez les Routes des Vins au Mexique - VisitMexico',
      description: 'Découvrez les meilleures routes des vins au Mexique. Profitez de dégustations, de paysages et de la culture vinicole. Planifiez votre visite maintenant!'
    },
    'DE': {
      title: 'Erkunden Sie Mexikos Weinstraßen - VisitMexico',
      description: 'Entdecken Sie die besten Weinstraßen in Mexiko. Genießen Sie Verkostungen, Landschaften und Weinkultur. Planen Sie jetzt Ihren Besuch!'
    },
    'PT': {
      title: 'Explore as Rotas do Vinho no México - VisitMexico',
      description: 'Descubra as melhores rotas do vinho no México. Desfrute de degustações, paisagens e cultura do vinho. Planeje sua visita agora!'
    },
    'IT': {
      title: 'Esplora le Strade del Vino in Messico - VisitMexico',
      description: 'Scopri le migliori strade del vino in Messico. Goditi degustazioni, paesaggi e cultura vinicola. Pianifica la tua visita ora!'
    },
    'JP': {
      title: 'メキシコのワインルートを探索する - VisitMexico',
      description: 'メキシコの最高のワインルートを発見。試飲、風景、ワイン文化を楽しんでください。今すぐ訪問を計画しよう！'
    },
    'CN': {
      title: '探索墨西哥的葡萄酒路线 - VisitMexico',
      description: '发现墨西哥最好的葡萄酒路线。享受品酒、美景和葡萄酒文化。立即计划您的访问！'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <WinesSlider data={language} />
      <LazyLoadComponent>
        <WinesDescription data={language} />
        <WinesCards data={language} />
      </LazyLoadComponent>
    </div>
  );
};

export default Wines;
