import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import './styles.css';
import ChildrensCultureTourismVideo from '../../components/childrensCultureTourismVideo';
import ChildrensCultureTourismGallery from '../../components/childrensCulturalTourismGallery';

const ChildrensCultureTourism = () => { 
    window?.scroll({
      top: 0
    });

return (
    <div className="main-content">
      <ChildrensCultureTourismVideo/>
      <LazyLoadComponent>
        <ChildrensCultureTourismGallery/>
      </LazyLoadComponent>
    </div>
  );
};

export default ChildrensCultureTourism;