import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../Context";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import experiencesEn from "../../assets/data/trenMaya/en/index.json";
import experiencesEs from "../../assets/data/trenMaya/es/index.json";
import NotFound from "../../components/notFound";

const Test = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(experiencesEs);

    window?.scroll({
        top: 0,
    });

    useEffect(() => {
        isLanguage === "ES"
            ? setLanguage(experiencesEs)
            : isLanguage === "EN"
                ? setLanguage(experiencesEn)
                : setLanguage(experiencesEs);
    }, [isLanguage]);

    return (
        <div className="main-content">
            {/* <MetaTags
        title={metaInfo[isLanguage].title}
        description={metaInfo[isLanguage].description}
      /> */}
            <LazyLoadComponent>
                <NotFound />
            </LazyLoadComponent>
        </div>
    )
}
export default Test;