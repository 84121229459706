import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './styles.css';

const HomePromotion = ({data}) => {
  return (
    <div className="home-the-most-viewed-ctn">
      <div className="cinta-roja">
      <img
        src={require(`../../assets/img/header/fondo_muertos.jpg`)}
        alt="separador"
        className="cinta-roja-image"
      />
      </div>
      <div className="home-promotion-container" >
        <a href="https://www.mexico360.com.mx/" target='_blank' rel='noopener noreferrer'>
        <LazyLoadImage src={require(`../../assets/img/${data?.promotion.banner1}`)}
            alt='mexico360' 
            className="home-promotion-img"/></a>
        <a href="https://www.tianguisturistico.com/" target='_blank' rel='noopener noreferrer'>
        <LazyLoadImage src={require(`../../assets/img/${data?.promotion.banner2}`)} 
            alt='evento' 
            className="home-promotion-img"/></a>
        {/* {sections()} */}
      </div>
    </div>
  );
};

export default HomePromotion;