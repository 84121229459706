import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import experiencesEs from '../../assets/data/experiences/es/index.json';
import experiencesEn from '../../assets/data/experiences/en/index.json';
import experiencesFr from '../../assets/data/experiences/fr/index.json';
import experiencesDe from '../../assets/data/experiences/de/index.json';
import experiencesPt from '../../assets/data/experiences/pt/index.json';
import experiencesIt from '../../assets/data/experiences/it/index.json';
import experiencesJp from '../../assets/data/experiences/jp/index.json';
import experiencesCn from '../../assets/data/experiences/cn/index.json';
import ExperienceVideo from '../../components/experienceVideo';
import ExperienceImages from '../../components/experienceImages';
import './styles.css';
import MetaTags from '../../components/metaTags';

const Experience = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(experiencesEs);
  const location = useLocation();
  const stateId = location?.pathname?.split('/')[2];
  const getStateInfo = language?.experiences?.filter(experience => experience.id === stateId);
  const data = getStateInfo?.[0];

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(experiencesEs)
    : isLanguage === 'EN' ? setLanguage(experiencesEn)
    : isLanguage === 'FR' ? setLanguage(experiencesFr)
    : isLanguage === 'DE' ? setLanguage(experiencesDe)
    : isLanguage === 'PT' ? setLanguage(experiencesPt)
    : isLanguage === 'IT' ? setLanguage(experiencesIt)
    : isLanguage === 'JP' ? setLanguage(experiencesJp)
    : isLanguage === 'CN' ? setLanguage(experiencesCn)
    : setLanguage(experiencesEs)
  }, [isLanguage]);

  if (!data) {
    return <Navigate to="/experiencias" />; // Redirigir a la página principal de experiencias si no se encuentra la experiencia
  }

  const metaInfo = {
    'ES': {
      title: 'Rutas Gastronómicas en México | Visit México',
      description: 'Descubre las mejores rutas gastronómicas en México: desde la cocina tradicional hasta restaurantes de renombre, en el sitio oficial de Visit México. Disfruta del sabor de México.'
    },
    'EN': {
      title: 'Gastronomic Routes in Mexico | Visit Mexico',
      description: 'Discover the best gastronomic routes in Mexico: from traditional cuisine to renowned restaurants, on the official Visit Mexico website. Enjoy the taste of Mexico.'
    },
    'FR': {
      title: 'Itinéraires Gastronomiques au Mexique | Visit Mexico',
      description: 'Découvrez les meilleures itinéraires gastronomiques au Mexique : de la cuisine traditionnelle aux restaurants renommés, sur le site officiel de Visit Mexico. Savourez le goût du Mexique.'
    },
    'DE': {
      title: 'Gastronomische Routen in Mexiko | Visit Mexico',
      description: 'Entdecken Sie die besten gastronomischen Routen in Mexiko: von traditioneller Küche bis zu renommierten Restaurants, auf der offiziellen Visit Mexico Website. Genießen Sie den Geschmack von Mexiko.'
    },
    'PT': {
      title: 'Rotas Gastronômicas no México | Visit Mexico',
      description: 'Descubra as melhores rotas gastronômicas no México: desde culinária tradicional até restaurantes renomados, no site oficial do Visit Mexico. Aproveite o sabor do México.'
    },
    'IT': {
      title: 'Percorsi Gastronomici in Messico | Visit Mexico',
      description: 'Scopri i migliori percorsi gastronomici in Messico: dalla cucina tradizionale ai ristoranti rinomati, sul sito ufficiale di Visit Mexico. Goditi il sapore del Messico.'
    },
    'JP': {
      title: 'メキシコのグルメルート | Visit Mexico',
      description: 'Visit Mexico公式ウェブサイトで、メキシコの最高のグルメルートを発見しよう：伝統的な料理から有名なレストランまで。メキシコの味を楽しもう。'
    },
    'CN': {
      title: '墨西哥美食路线 | Visit Mexico',
      description: '在Visit Mexico官方网站上发现墨西哥最佳的美食路线：从传统美食到知名餐厅。享受墨西哥的味道。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <ExperienceVideo data={data} />
      <LazyLoadComponent>
        <ExperienceImages data={data} />
      </LazyLoadComponent>
    </div>
  );
};

export default Experience;
