import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Context } from '../../Context';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Modal from 'react-modal';
import { FaDownload } from 'react-icons/fa';
import './styles.css';

import descargasEs from '../../assets/data/descargas/es/index.json';
import descargasDe from '../../assets/data/descargas/de/index.json';
import descargasFr from '../../assets/data/descargas/fr/index.json';
import descargasEn from '../../assets/data/descargas/en/index.json';
import descargasIt from '../../assets/data/descargas/it/index.json';
import descargasPt from '../../assets/data/descargas/pt/index.json';
import descargasJp from '../../assets/data/descargas/jp/index.json';
import descargasCn from '../../assets/data/descargas/cn/index.json';

Modal.setAppElement('#root'); // Esto es necesario para la accesibilidad

const EstadoPage = () => {
    const { isLanguage } = useContext(Context);
    const [ setLanguage] = useState(descargasEs);
    const [languageData, setLanguageData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const location = useLocation();
    const navigate = useNavigate(); // Cambiado de useHistory a useNavigate
    const estadoParam = location?.pathname?.split('/')[2]; // Ajusta según el formato de la URL

    useEffect(() => {
        const languageMap = {
            'ES': descargasEs,
            'DE': descargasDe,
            'FR': descargasFr,
            'EN': descargasEn,
            'IT': descargasIt,
            'PT': descargasPt,
            'JP': descargasJp,
            'CN': descargasCn
        };
    
        // Si no coincide con ningún idioma, se usa 'ES' como fallback
        const selectedLanguage = languageMap[isLanguage] || descargasEs;
    
        setLanguage(selectedLanguage);
        setLanguageData(selectedLanguage);
    }, [isLanguage]);

    // Filtrar la información del estado basado en el parámetro de la URL
    const getEstadoInfo = (data, estadoNombre) => {
        return data.estados?.find(estado => estado.title === estadoNombre);
    };

    const estadoInfo = getEstadoInfo(languageData, estadoParam);

    const openModal = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    const downloadFile = (file) => {
        const link = document.createElement('a');
        link.href = require(`../../assets/img/${file.src}`);
        link.download = file.src.split('/').pop(); // Nombre del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleBack = () => {
        navigate(-1); // Usar navigate en lugar de history
    };

    return (
        <div>
            <div className="hero-slider-container-estado">
                {estadoInfo?.backgroundImageEstado && (
                    <LazyLoadImage
                        src={require(`../../assets/img/${estadoInfo.backgroundImageEstado}`)}
                        alt={estadoInfo.title}
                        effect="blur"
                        className="background-image"
                        wrapperClassName="background-image-wrapper"
                    />
                )}
                <div className="overlay">
                    <div className="content">
                        <h1>{estadoInfo?.etiquetaTitle}</h1>
                        <nav className="breadcrumb">
                            <button onClick={handleBack} className="breadcrumb-button">{estadoInfo?.botonVolver}</button>
                            <span className="breadcrumb-separator">/</span>
                            <span className="breadcrumb-item">{estadoInfo?.etiquetaTitle}</span>
                        </nav>
                    </div>
                </div>
            </div>

            {estadoInfo ? (
                <div>
                    {estadoInfo?.fotoTitle && (
                        <h2 className='subtitulo-page'>{estadoInfo.fotoTitle}</h2>
                    )}
                    
                    <div className="image-grid">
                        {estadoInfo.images.map((image, index) => (
                            <div key={index} className="image-container" onClick={() => openModal(image.src)}>
                                <LazyLoadImage
                                    src={require(`../../assets/img/${image.src}`)}
                                    alt={`Imagen ${index + 1}`}
                                    effect="blur"
                                    className="image-lazy-estado"
                                />
                                <div className="image-text">{image.title}</div>
                                <div className="image-text-bg"></div>
                            </div>
                        ))}
                    </div>
                    {estadoInfo?.videoTitle && (
                        <h2 className='subtitulo-page'>{estadoInfo.videoTitle}</h2>
                    )}
                    
                    <div className="video-grid">
                        {estadoInfo.videos.map((video, index) => (
                            <div key={`${video.src}-${index}`} className="video-container">
                                <video className="video-player" controls key={video.src}>
                                    <source src={require(`../../assets/img/${video.src}`)} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="video-info">
                                    <div className="video-title">{video.title}</div>
                                    <button className="download-button" onClick={() => downloadFile(video)}>
                                        {estadoInfo.botonDescargar}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <p>No se encontró información para este estado.</p>
            )}

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                className="modal"
                overlayClassName="modal-overlay"
            >
                {selectedImage && (
                    <div className="modal-content">
                        <LazyLoadImage
                            src={require(`../../assets/img/${selectedImage}`)}
                            alt="Imagen seleccionada"
                            className="modal-image"
                        />
                        <button className="download-button" onClick={() => downloadFile({ src: selectedImage })}>
                            <FaDownload /> {estadoInfo.botonDescargar}
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default EstadoPage;