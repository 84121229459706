import React from 'react';
import "./styles.css";

const TermsAndConditions = () => {
    window?.scroll({
        top: 51,
        behavior: "smooth",
    });
    return (
        <div className="terms-and-conditions">
            <h1>Términos y condiciones del uso del sitio de Visit Mexico</h1>
            <p>La utilización de este sitio y de cualquiera de las aplicaciones del mismo constituye el pleno y expreso consentimiento por parte del usuario para observar y sujetarse respecto de cada uno de los términos y condiciones que aquí se contienen, así como respecto de las políticas de privacidad, políticas de seguridad y, en su caso, cualesquiera otros documentos que conformen parte o regulen la participación del usuario en este sitio. En el supuesto que cualquiera de los términos a los que habrá de sujetarse el usuario cuando acceda a este sitio sean contrarios a sus intereses, deberá abstenerse de hacer uso de este.</p>
            <h3>Obligaciones del usuario</h3>
            <ul>
                <li>Al utilizar este sitio me obligo a cumplir con los términos y condiciones de su uso, establecidos en el presente documento (en adelante, los “términos y condiciones"), por lo que reconozco haber leído, entendido y estar de acuerdo en sujetarme a sus términos y condiciones. De manera que, al acceder al sitio, estoy de acuerdo en acatar lo anterior.</li>
                <li>La información y los servicios que ofrece Visit Mexico, (en adelante el Sitio) hacen referencia exclusivamente, a la normatividad que los rige en los Estados Unidos Mexicanos.</li>
                <li>Por el hecho de hacer uso de la información y de los Servicios, el usuario expresa su aceptación y está de acuerdo con los términos y condiciones del uso del sitio, así como con las modificaciones que, en su caso, se realicen.</li>
                <li>Los usuarios no residentes en los Estados Unidos Mexicanos que deseen acceder al sitio deberán asegurarse de que el acceso y uso de este, de su contenido y/o de los servicios, les esté permitido de conformidad con su propia legislación.</li>
                <li>El usuario que utilice este sitio fuera de la República Mexicana se hará responsable del cumplimiento de todas las leyes de dicha jurisdicción.</li>
            </ul>
            <h3>El uso del sitio es bajo la exclusiva responsabilidad del usuario.</h3>
            <ul>
                <li>Se autoriza al Usuario a visualizar y descargar los materiales contenidos en el sitio solamente para su uso personal y no para uso comercial.</li>
                <li>El Usuario deberá conservar en todas las copias de los materiales descargados, todos los avisos sobre derechos de autor y propiedad intelectual de los materiales, contenidos en los mismos;</li>
                <li>El Usuario no deberá modificar, reproducir o mostrar pública o comercialmente los materiales, ni podrá distribuir o utilizarlos con algún propósito público o comercial.</li>
                <li>El Usuario no deberá transferir los materiales a ningún tercero.</li>
                <li>Nos reservamos el derecho de suspender, interrumpir o dejar de operar el sitio y los servicios, en cualquier momento.</li>
            </ul>
        </div>
    );
};

export default TermsAndConditions;