import React from 'react';
import './styles.css';

const MuertosCinta = ({ data }) => {
  return (
    <div className="cinta-roja">
      <img
        src={require(`../../assets/img/${data.cintaRoja}`)}
        alt="separador"
        className="cinta-roja-image"
      />
    </div>
  );
};

export default MuertosCinta;
