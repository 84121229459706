import React from 'react';
import './styles.css'; // Asegúrate de tener tu archivo de estilos CSS

const AcapulcoReelSection = ({ data }) => {
  return (
    <div className="acapulco_reel_container">
      <video
        src={require(`../../assets/img/${data.reel}`)}
        controls
        className="acapulco_reel_video"
      />
      <div className="acapulco_reel_content">
        <h2 className='maria-islands-description-content-title'>{data.reelh2}</h2>
        <p>
          {data.reelp1}{' '}
          <a href="https://www.gob.mx/sectur/prensa/inicia-la-etapa-acapulco-se-transforma-contigo-para-la-reactivacion-turistica-del-puerto" target="_blank" rel="noopener noreferrer">{data.link1}</a>
        </p>
        <p>
          {data.p2}
          <a href="https://www.gob.mx/sectur" target="_blank" rel="noopener noreferrer"> {data.link2} </a>

        </p>
        <p>
          {data.p3}
          <a href="https://www.gob.mx/sectur" target="_blank" rel="noopener noreferrer"> {data.link2} </a>
        </p>
        <div className="acapulco_reel_image">
        <a href="https://visitacapulco.travel/" target="_blank" rel="noopener noreferrer">
          <img
            src={require(`../../assets/img/${data.reelImage}`)}
            alt="Acapulco Reel"
            className="acapulco_reel_img"
          />
        </a>
      </div>
      </div>
    </div>
  );
};

export default AcapulcoReelSection;