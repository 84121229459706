import React from "react";
import "./styles.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import videoFile1 from "../../assets/videos/cap2.mp4";
import videoFile2 from "../../assets/videos/cap3.mp4";
import videoFile3 from "../../assets/videos/cap4.mp4";
import videoFile4 from "../../assets/videos/cap5.mp4";
const ChildrensCultureTourismGallery = () => {
  return (
    <section className="childrens-culture-tourism-gallery-ctn">
      <LazyLoadImage 
                  src={require(`../../assets/img/promotion/XICO-CLR-BNN.png`)}
                  className="childrens-culture-tourism-gallery-img"/>      
      <div className="video-grid">
        <div className="video-item">
          <video width="100%" height="100%" controls autoPlay muted loop>
                  <source src={videoFile1} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
        </div>
        <div className="video-item">
          <video width="100%" height="100%" controls autoPlay muted loop>
                  <source src={videoFile2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
        </div>
        <div className="video-item">
          <video width="100%" height="100%" controls autoPlay muted loop>
                  <source src={videoFile3} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
        </div>
        <div className="video-item">
          <video width="100%" height="100%" controls autoPlay muted loop>
                  <source src={videoFile4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
        </div>
      </div>
    </section>
  );
};

export default ChildrensCultureTourismGallery;
