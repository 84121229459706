import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'; // Importa Link
import { Context } from '../../Context';


import postEs from '../../assets/data/newBlog/es/index.json';
import postEn from '../../assets/data/newBlog/en/index.json';
import postFr from '../../assets/data/newBlog/fr/index.json';
import postDe from '../../assets/data/newBlog/de/index.json';
import postPt from '../../assets/data/newBlog/pt/index.json';
import postIt from '../../assets/data/newBlog/it/index.json';
import postJp from '../../assets/data/newBlog/jp/index.json';
import postCn from '../../assets/data/newBlog/cn/index.json';

import './styles.css';
import MetaTags from '../../components/metaTags';

const SinglePost = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(postEs);
  const location = useLocation();
  const postId = location?.pathname?.split('/')[3];
  const getPostInfo = language?.posts?.filter(post => post.id === postId);
  const data = getPostInfo[0]?.post;

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(postEs)
    : isLanguage === 'EN' ? setLanguage(postEn)
    : isLanguage === 'FR' ? setLanguage(postFr)
    : isLanguage === 'DE' ? setLanguage(postDe)
    : isLanguage === 'PT' ? setLanguage(postPt)
    : isLanguage === 'IT' ? setLanguage(postIt)
    : isLanguage === 'JP' ? setLanguage(postJp)
    : isLanguage === 'CN' ? setLanguage(postCn)
    : setLanguage(postEs)
  }, [isLanguage]);

  const contentCase = (item) => {
    switch(item.type) {
      case 'title':
        return <h2 className="post-content-title">{item.content}</h2>
      case 'subtitle':
        return <h3 className="post-content-subtitle">{item.content}</h3>
      case 'text':
        return <p className="post-content-text">{item.content}</p>
      case 'image':
        return <figure className="post-content-ctn">
          <img className="post-content-img" src={require(`../../assets/img/newBlog/${item.urlImg}`)} alt={item.urlImg} />
        </figure>
      default:
        return null;
    };
  };

  const metaInfo = {
    'ES': {
      title: 'Explorando México: Aventuras, Cultura y Más',
      description: 'Explora México a través de historias fascinantes en el blog de Visit México. Descubre aventuras, cultura y mucho más.'
    },
    'EN': {
      title: 'Exploring Mexico: Adventures, Culture, and More',
      description: 'Explore Mexico through fascinating stories on Visit Mexico\'s blog. Discover adventures, culture, and much more.'
    },
    'FR': {
      title: 'Découvrir le Mexique: Aventures, Culture et Plus',
      description: 'Découvrez le Mexique à travers des histoires fascinantes sur le blog de Visit Mexique. Explorez des aventures, la culture et bien plus encore.'
    },
    'DE': {
      title: 'Mexiko erkunden: Abenteuer, Kultur und Mehr',
      description: 'Erkunden Sie Mexiko durch faszinierende Geschichten auf dem Blog von Visit Mexiko. Entdecken Sie Abenteuer, Kultur und vieles mehr.'
    },
    'PT': {
      title: 'Explorando o México: Aventuras, Cultura e Mais',
      description: 'Explore o México através de histórias fascinantes no blog do Visit México. Descubra aventuras, cultura e muito mais.'
    },
    'IT': {
      title: 'Esplorare il Messico: Avventure, Cultura e Altro',
      description: 'Esplora il Messico attraverso storie affascinanti sul blog di Visit Messico. Scopri avventure, cultura e molto altro.'
    },
    'JP': {
      title: 'メキシコ探検: 冒険、文化、その他',
      description: 'Visit Mexicoのブログで魅力的なストーリーを通じてメキシコを探検しよう。冒険、文化、その他を発見しよう。'
    },
    'CN': {
      title: '探索墨西哥: 冒险、文化与更多',
      description: '通过Visit Mexico的博客中的精彩故事来探索墨西哥。发现冒险、文化和更多。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <div className="blog-banner">
        <img src={require(`../../assets/img/${getPostInfo[0]?.urlImg}`)} alt="Banner post" />
        <div className="blog-banner-content">
          <h1>{getPostInfo[0]?.name}</h1>
        </div>
      </div>
      <div className='post-content'>
        {data?.map((item, index) => contentCase(item))}
      </div>
      <div className="back-to-blog-container">
        <Link to="/blog" className="back-to-blog-link">{getPostInfo[0]?.backButton}</Link>
      </div>
    </div>
  );
};

export default SinglePost;
