import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import MiceVideo from '../../components/miceVideo';
import miceEs from '../../assets/data/mice/es/index.json';
import miceEn from '../../assets/data/mice/en/index.json';
import MiceHeader from '../../components/miceHeader';
import MiceProtagonista from '../../components/miceProtagonista';
import MiceSlideText from '../../components/miceSlideText';
import './styles.css';
import MiceReuniones from '../../components/miceReuniones';
import MiceClasificacion from '../../components/miceClasificacion';
import MiceMexico from '../../components/miceMexico';
import MiceCollage from '../../components/miceCollage';
import MetaTags from '../../components/metaTags';

const Mice = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(miceEs);

    window?.scroll({
        top: 0
    });

    useEffect(() => {
        isLanguage === 'ES' ? setLanguage(miceEs)
        : isLanguage === 'EN' ? setLanguage(miceEn) 
        : setLanguage(miceEs)
    }, [isLanguage]);

    const metaInfo = {
        'ES': {
            title: 'Eventos MICE en México - VisitMexico',
            description: 'Organiza tus eventos MICE en México. Descubre lugares excepcionales para reuniones, incentivos, conferencias y exposiciones.'
        },
        'EN': {
            title: 'MICE Events in Mexico - VisitMexico',
            description: 'Host your MICE events in Mexico. Discover exceptional venues for meetings, incentives, conferences, and exhibitions.'
        },
        'FR': {
            title: 'Événements MICE au Mexique - VisitMexico',
            description: 'Organisez vos événements MICE au Mexique. Découvrez des lieux exceptionnels pour réunions, incentives, conférences et expositions.'
        },
        'DE': {
            title: 'MICE-Veranstaltungen in Mexiko - VisitMexico',
            description: 'Veranstalten Sie Ihre MICE-Events in Mexiko. Entdecken Sie außergewöhnliche Orte für Meetings, Incentives, Konferenzen und Ausstellungen.'
        },
        'PT': {
            title: 'Eventos MICE no México - VisitMexico',
            description: 'Organize seus eventos MICE no México. Descubra locais excepcionais para reuniões, incentivos, conferências e exposições.'
        },
        'IT': {
            title: 'Eventi MICE in Messico - VisitMexico',
            description: 'Organizza i tuoi eventi MICE in Messico. Scopri luoghi eccezionali per riunioni, incentivi, conferenze ed esposizioni.'
        },
        'JP': {
            title: 'メキシコのMICEイベント - VisitMexico',
            description: 'メキシコでMICEイベントを開催しましょう。会議、インセンティブ、会議、展示会のための特別な会場を発見してください。'
        },
        'CN': {
            title: '墨西哥的MICE活动 - VisitMexico',
            description: '在墨西哥举办您的MICE活动。发现会议、奖励、会议和展览的特别场地。'
        }
    };

    return (
        <div className="main-content">
            <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
            <MiceVideo data={language} />
            <LazyLoadComponent>
                <MiceHeader data={language} />
                <MiceProtagonista data={language} />
                <MiceSlideText slides={language.slides} /> {/* Pasa los textos del slide como una propiedad */}
                <MiceReuniones data={language} />
                <MiceClasificacion data={language} />
                <MiceMexico data={language} />
                <MiceCollage data={language} />
            </LazyLoadComponent>
        </div>
    );
};

export default Mice;
