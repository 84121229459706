import React, { useState, useEffect, useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Context } from '../../Context';
import { useNavigate } from 'react-router-dom';
import 'react-select-search/style.css';
import useWindowSize from '../../utils/useWindowSize';
import listSecondaryEs from '../../assets/data/header/es/listSecondary.json';
import listSecondaryEn from '../../assets/data/header/en/listSecondary.json';
import listPrimaryEs from '../../assets/data/header/es/listPrimary.json';
import listPrimaryEn from '../../assets/data/header/en/listPrimary.json';
import listLanguages from '../../assets/data/header/listLanguages.json';
import optionsEs from '../../assets/data/header/es/listSearch.json';
import optionsEn from '../../assets/data/header/en/listSearch.json';
import footerEs from "../../assets/data/footer/es/index.json";
import footerEn from "../../assets/data/footer/en/index.json";
import Logo from '../../assets/img/header/logo.png';
import MenuOpenImg from '../../assets/img/header/menu-open.svg';
import './styles.css';
import menuIcons from "./data.json";

const HeaderNuevo = () => {
  const windowsSize = useWindowSize();
  const { isLanguage, selectedLanguage } = useContext(Context);
  const [listSecondary, setListSecondary] = useState(listSecondaryEs);
  const [listPrimary, setListPrimary] = useState(listPrimaryEs);
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState();
  const [options, setOptions] = useState(optionsEs);
  const [menuOpen, setMenuOpen] = useState(null);
  const [hideShow, setHideShow] = useState('menu-none');
  const [showSubmenuPrimary, setShowSubmenuPrimary] = useState(false);
  const [showSubmenuSecondary, setShowSubmenuSecondary] = useState(false);
  const [language, setLanguage] = useState(footerEs);

  const [isScrolled, setIsScrolled] = useState(false);

  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleMenuClick = (id) => {
    if (id === 12) {
      setShowSubMenu(!showSubMenu);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    isLanguage === "ES"
      ? setLanguage(footerEs)
      : isLanguage === "EN"
        ? setLanguage(footerEn)
        : setLanguage(footerEs);
  }, [isLanguage]);

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
    menuOpen ? setIsScrolled(false) : setIsScrolled(true);
  };

  const changeLanguage = (e) => {
    selectedLanguage(e.value);
  };

  const listMenuSecondary = () => (
    listSecondary?.map(item => (
      item.id === "acapulco"
        ?
        <li className="header-menu-li-secondary special-dia-de-muertos" key={item.name}>
          <Link to={item.dir} onClick={handleMenuOpen} className="special-dia-de-muertos-link">
            <img
              src={require('../../assets/img/header/acapulco_logo.png')} // Ruta de la imagen de Día de Muertos
              alt="Acapulco"
              className="dia-de-muertos-icon"
            />
          </Link>
        </li>
        : item.id === "mapa"
          ?
          <li className="header-menu-li-secondary special-dia-de-muertos" key={item.name}>
            <a href={item?.dir} target="_blank" rel="noopener noreferrer">
              <img
                width={75}
                src={require(`../../assets/img/${item?.imageSrc}`)}
                alt={item?.alt}
                className="dia-de-muertos-icon"
              />
            </a>
          </li>
          :
          <li className="header-menu-li-secondary" key={item.name}>
            <Link to={item.dir} onClick={handleMenuOpen}>
              {item.name}
            </Link>
          </li>
    ))
  );

  const handleShowSubmenuPrimary = () => {
    setShowSubmenuPrimary(!showSubmenuPrimary);
  };

  const handleShowSubmenuSecondary = () => {
    setShowSubmenuSecondary(!showSubmenuSecondary);
  };

  const listMenuPrimary = () => (
    listPrimary?.map(item => (
      <li className="header-menu-li-primary" key={item.name}>
        {!item?.menu
          ?
          <Link to={item.dir} onClick={handleMenuOpen}>
            {item.name}
          </Link>
          :
          <button onClick={handleShowSubmenuPrimary}>
            {item.name}
          </button>
        }
        {item?.menu &&
          <div className={`header-ctn-submenu-primary ${showSubmenuPrimary ? 'show-menu-primary' : 'hide-menu-primary'}`}>
            {item?.menu?.map(subItem => (
              <div className="header-ctn-submenu-link-primary" key={subItem.name}>
                {!subItem?.menu
                  ?
                  <Link to={subItem.dir} onClick={handleMenuOpen} className="header-submenu-link-primary">
                    {subItem.name}
                  </Link>
                  :
                  <button onClick={handleShowSubmenuSecondary} className="header-submenu-link-primary">
                    {subItem.name}
                  </button>
                }
                {subItem?.menu &&
                  <div className={`header-ctn-sub-submenu-link-primary ${showSubmenuSecondary ? 'show-menu-secondary' : 'hide-menu-secondary'}`}>
                    {subItem?.menu?.map(subSbuItem => (
                      <div className="header-ctn-submenu-link-primary" key={subSbuItem.name}>
                        <Link to={subSbuItem.dir} onClick={handleMenuOpen} className="header-submenu-link-primary">
                          {subSbuItem.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                }
                <LazyLoadImage
                  src={require(`../../assets/img/header/${subItem.image}`)}
                  alt='logo visitmexico'
                  className={subItem.claseImage}
                />
              </div>
            ))}
          </div>
        }
      </li>
    ))
  );

  const languages = () => (
    listLanguages?.map(item => (
      <option value={item.value} key={item.name} id={`flag-${item.value}`}>
        {item.name}
      </option>
    ))
  );

  useEffect(() => {
    const languageData = {
      ES: { listSecondary: listSecondaryEs, listPrimary: listPrimaryEs, options: optionsEs },
      EN: { listSecondary: listSecondaryEn, listPrimary: listPrimaryEn, options: optionsEn },
    };

    const selectedLanguageData = languageData[isLanguage] || languageData.ES;

    setListSecondary(selectedLanguageData.listSecondary);
    setListPrimary(selectedLanguageData.listPrimary);
    setOptions(selectedLanguageData.options);
  }, [isLanguage]);

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl);
    }
  }, [redirectUrl]);

  useEffect(() => {
    if (menuOpen) {
      setHideShow('menu-show');
    } else if (windowsSize?.isMobile) {
      setHideShow('menu-hide');
    }
  }, [menuOpen, windowsSize]);

  const handleSearch = (data) => {
    setRedirectUrl(data);
  };

  const socialMediaLinks = () => (
    <div className="social-media-links">
      <div className="social-media-icons">
        {language?.socialMedia?.map((social) => (
          <a
            key={social.name}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="header-contact-rss"
              src={require(`../../assets/img/${isScrolled ? social.iconSrcAlt : social.iconSrc}`)}
              alt={social.alt}
            />
          </a>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <div className={`header-ctn ${isScrolled ? 'scrolled' : ''} d-flex justify-content-between `}>
        <div className="header-ctn-logo">
          <Link to={'/'} onClick={() => setMenuOpen(false)}>
            <LazyLoadImage
              src={Logo}
              alt='logo visitmexico'
              className="header-logo"
            />
          </Link>
          <button onClick={handleMenuOpen} type='button' className='header-menu-open'>
            <img src={MenuOpenImg} className='header-menu-open-img' alt="menu-open" />
          </button>
        </div>
        <nav className={`header-ctn-menu ${hideShow}`}>
          <div className="social-ctn solo-screen">
            <div className="menu-container">
              <div className="menu">
                {menuIcons.map((icon) => (
                  <div key={icon.id} className={`menu-item ${icon.id === 12 ? 'has-submenu' : ''}`}>
                    <Link
                      className="no-underline"
                      to={icon.url}
                      {...(icon.url.startsWith('http') ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
                    >
                      <img
                        src={require(`../../assets/img/menu/${isScrolled ? icon.srcAlt : icon.src}`)}
                        alt={icon.alt}
                        className="icon-menu"
                      />
                      <span className={`tooltipMenu ${isScrolled ? "grayLink" : "whiteLink"}`}>{icon.alt === "Experiencias" ? "" : icon.alt}</span>
                    </Link>
                    {icon.id === 12 && (
                      <div className="submenu-flotante">
                        <h3 className='submenu-subtitle'>{icon.alt}</h3>
                        <div className="submenu-grid">
                          {icon.menu.map((item, index) => (
                            <Link key={index} to={item.dir} className="submenu-item">
                              <span>{item.name}</span>
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="header-ctn-language">
            <Select
              options={listLanguages}
              className="header-menu-select react-select-container"
              classNamePrefix="react-select"
              onChange={(e) => changeLanguage(e)}
              id="selectLanguage"
              formatOptionLabel={lenguage => (
                <div className="lenguage-option">
                  <img
                    src={require(`../../assets/img/header/${lenguage.value}.svg`)}
                    alt={`lenguage-${lenguage.label}`}
                    className="header-flag-img"
                  />
                  {/* <span>{lenguage.label}</span> */}
                </div>
              )}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: '#a6208c',
                },
              })}
              defaultValue={listLanguages[0]}
            />
          </div>
          <div className="menu-mobile-container solo-mobile">
            <div className="menu-mobile">
              {menuIcons.map((icon) => (
                <div key={icon.id} className="menu-mobile-item">
                  <Link to={icon.url} onClick={handleMenuOpen} {...(icon.url.startsWith('http') ? { target: '_blank', rel: 'noopener noreferrer' } : {})} >
                    <img src={require(`../../assets/img/menu/${isScrolled ? icon.srcAlt : icon.src}`)}
                      alt={icon.alt} className="icon-menu-mobile" />
                    {/* <span className={`tooltipMenu ${isScrolled ? "grayLink" : "whiteLink"}`}>{icon.alt}</span> */}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="menu-mobile-container solo-mobile">
            <div className="menu-mobile">
              {menuIcons.map((icon) => (
                <div key={icon.id} className="menu-mobile-item">
                  <Link to={icon.url} onClick={handleMenuOpen} {...(icon.url.startsWith('http') ? { target: '_blank', rel: 'noopener noreferrer' } : {})} >
                    {/* <span>{icon.alt}</span> */}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </nav>
      </div>
      <div className="social-media-container">
        {language?.socialMedia?.map((social) => (
          <a
            key={social.name}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require(`../../assets/img/${social.iconSrc}`)}
              alt={social.alt}
              width={30}
            />
          </a>
        ))}
      </div>
      <div className="doll-container">
        <a>
          <img
            src={require(`../../assets/img/menu/doll.png`)}
            alt="doll"
            width={100}
          />
        </a>
      </div>
    </>
  );
};

export default HeaderNuevo;
