import React from 'react';
import './styles.css';
import videoFile from '../../assets/videos/mainXico.mp4';

const ChildrensCultureTourismVideo = () => {
  return (
    <section className="maria-islands-video-top-ctn">
      <video width="100%" height="100%" controls autoPlay muted loop>
        <source src={videoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>
  )
};

export default ChildrensCultureTourismVideo;
