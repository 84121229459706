import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './styles.css';
import HomeMainEvents from '../homeMainEvents';

const HomeEvents = ({ data }) => {
  const listHomeTravelInspiration = data?.aprilEvents;

  const sections = () => (
    listHomeTravelInspiration?.destinations?.map((item, index) => (
      <div className={`home-events-sections-card-${index}`} key={item?.name}>
        {/* <p className="home-the-most-viewed-sections-card-hover-title">{item?.name}</p> */}
        <Link to={item?.dir} target='_blank' rel='noopener noreferrer'>
          <LazyLoadImage
            src={require(`../../assets/img/header/${item?.image}`)}
            alt={item?.name}
            className="home-events-card-img"
          />
          <div className="home-events-sections-card-hover">
            {/* <p className="home-the-most-viewed-sections-card-hover-title">{item?.name}</p> */}
          </div>
        </Link>
      </div>
    ))
  );

  return (<>
    <div className="home-events-ctn">
      <div>
        <h3 className="home-the-most-viewed-title">{listHomeTravelInspiration?.title}</h3>
        <h4 className="home-events-subtitle">{listHomeTravelInspiration?.subtitle}</h4>
        <div className="home-events-sections">
          {sections()}
        </div>
        </div>
      <div><HomeMainEvents data={data}/></div>
    </div></>
  );
};

export default HomeEvents;