import React from 'react';
import './styles.css';
import mainVideo from '../../assets/videos/mainVideo.mp4'; // Importa el video

const MainVideo = () => {
  return (<>
    <section className="main-video-top-ctn">
    <div className="fullscreen-video-container">
      <video className="fullscreen-video" controls autoPlay muted loop>
        <source src={mainVideo} type="video/mp4" />
        Tu navegador no soporta la etiqueta de video.
      </video>
      </div>
    </section>
  </>
  )
};

export default MainVideo;
