import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../Context";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import experiencesEn from "../../assets/data/trenMaya/en/index.json";
import experiencesEs from "../../assets/data/trenMaya/es/index.json";
import TrenMayaContent from "../../components/trenMayaContent";
import TrenMayaVideos from "../../components/trenMayaVideos";
import "./styles.css";
import MetaTags from "../../components/metaTags";
import TrenMayaCallToAction from "../../components/trenMayaCallToAction";
import TrenMayaAdvertising from "../../components/trenmayaAdvertising";
import TrenMayaBanner from "../../components/trenMayaBanner";

const TrenMaya = () => {
  const { isLanguage } = useContext(Context);
  const [language, setLanguage] = useState(experiencesEs);

  window?.scroll({
    top: 0,
  });

  useEffect(() => {
    isLanguage === "ES"
      ? setLanguage(experiencesEs)
      : isLanguage === "EN"
      ? setLanguage(experiencesEn)
      : setLanguage(experiencesEs);
  }, [isLanguage]);

  const metaInfo = {
    ES: {
      title: "Tren Maya: Descubre la Ruta y Cultura de México",
      description:
        "Explora el Tren Maya, una ruta que revela la riqueza cultural y natural de México. Descubre sus destinos y experiencias únicas con Visit México.",
    },
    EN: {
      title: "Maya Train: Discover Mexico's Route and Culture",
      description:
        "Explore the Maya Train, a route that unveils Mexico's cultural and natural richness. Discover its destinations and unique experiences with Visit Mexico.",
    },
    FR: {
      title: "Train Maya: Découvrez la Route et la Culture du Mexique",
      description:
        "Explorez le Train Maya, un itinéraire qui dévoile la richesse culturelle et naturelle du Mexique. Découvrez ses destinations et expériences uniques avec Visit Mexique.",
    },
    DE: {
      title: "Maya Zug: Entdecken Sie Mexikos Route und Kultur",
      description:
        "Erkunden Sie den Maya Zug, eine Route, die die kulturelle und natürliche Vielfalt Mexikos offenbart. Entdecken Sie seine Ziele und einzigartigen Erlebnisse mit Visit Mexiko.",
    },
    PT: {
      title: "Trem Maia: Descubra a Rota e Cultura do México",
      description:
        "Explore o Trem Maia, uma rota que revela a riqueza cultural e natural do México. Descubra seus destinos e experiências únicas com Visit México.",
    },
    IT: {
      title: "Treno Maya: Scopri la Rotta e la Cultura del Messico",
      description:
        "Esplora il Treno Maya, un percorso che rivela la ricchezza culturale e naturale del Messico. Scopri le sue destinazioni e le esperienze uniche con Visit Messico.",
    },
    JP: {
      title: "マヤ列車: メキシコのルートと文化を発見する",
      description:
        "マヤ列車を探索し、メキシコの文化的・自然的豊かさを明らかにするルート。Visit Mexicoでその目的地とユニークな体験を発見しよう。",
    },
    CN: {
      title: "玛雅火车: 发现墨西哥的路线和文化",
      description:
        "探索玛雅火车，揭示墨西哥文化和自然的丰富多彩。通过Visit Mexico发现其目的地和独特体验。",
    },
  };

  return (
    <div className="main-content">
      <MetaTags
        title={metaInfo[isLanguage].title}
        description={metaInfo[isLanguage].description}
      />
      <TrenMayaBanner data={language} />
      <LazyLoadComponent>
        <TrenMayaAdvertising data={language} />
        <TrenMayaCallToAction data={language} />
        <TrenMayaContent data={language} />
        <TrenMayaVideos data={language} />
      </LazyLoadComponent>
    </div>
  );
};

export default TrenMaya;
