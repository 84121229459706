import React from "react";
import "./styles.css";

const TrenMayaCallToAction = ({ data }) => {
  return (
    <div className="call-to-action-container">
      <a
        href="https://www.trenmaya.gob.mx/"
        target="_blank"
        rel="noopener noreferrer"
        className="call-to-action-button"
      >
        {data.callToActionLabel}
      </a>
    </div>
  );
};

export default TrenMayaCallToAction;
