import React, { useState } from 'react';
import "./styles.css";
import CloudBG from '../../assets/backgrounds/cloud';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const NotFound = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleShowDetails = (index) => {
    setShowDetails(true);
    setSelectedIndex(index);
  };
  const cardData = [
    { img: "Tlaxco.png", title: "Tlaxco, Tlaxcala", text: "El municipio de Múzquiz fue incorporado al programa de Pueblos Mágicos el 11 de octubre del 2018." },
    { img: "Ixtenco.png", title: "Ixtenco, Tlaxcala", text: "Ixtenco fue nombrado como el tercer Pueblo Mágico de Tlaxcala el 27 de julio del 2023." },
    { img: "Huamantla.png", title: "Huamantla, Tlaxcala", text: "Huamantla fue nombrado Pueblo Mágico el 14 de agosto de 2007." },
  ];
  const location = [
    { history: "En 1737 fue fundada Santa Rosa María del Sacramento como un presidio español de protección contra los ataques de los indios que hostigaban la región. Recibe su actual denominación en honor al militar, el general Melchor de Ecay y Múzquiz de Arrieta, originario del lugar y presidente interino de la República en 1832; se decidió invalidar el nombre anterior para designarlo villa de Múzquiz en 1850.En la zona se encuentra una colonia de la tribu kikapú, donde se habla el idioma kikapú, que es la única lengua álgica que subsiste en México; los kikapús son una tribu norteamericana que recibieron de Juárez estas tierras.", text: "Tlaxco colinda con el Estado de Puebla al norte, con Atlangatepec, Tetla y Muñoz de Domingo Arenas al sur, con Emiliano Zapata y Lázaro Cárdenas al oriente y finalmente al poniente con el Estado de Hidalgo y el municipio de Benito Juárez. El acceso es vía terrestre y vía aérea.", maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60132.478624918396!2d-98.1239816!3d19.615327299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d017ce02b008e9%3A0x4a86f3ef164be249!2sTlaxco%2C%20Tlax.!5e0!3m2!1ses-419!2smx!4v1739821546720!5m2!1ses-419!2smx" },
    { history: "En 1532 la cédula real por el Rey Carlos V permitió la fundación del pueblo Ixtenco a los caciques españoles Don Diego Gabriel, Don Juan Ponce de León, Don Francisco Contreras y Don Antonio Gómez Fabián quienes participaron en la conquista. Por ello, el Pueblo de San Juan Bautista Ixtenco fue fundado el 8 de enero de 1532. A Ixtenco le corresponden 2 ranchos y 4 haciendas.", text: "Ixtenco colinda al norte con el municipio de Huamantla, al sur con el municipio de Trinidad Sánchez Santos, al oriente con el Estado de Puebla y al poniente con Huamantla e igualmente con Trinidad Sánchez Santos.. Dicho territorio comprende una superficie de 43.56 Km2, comprendiendo el 1.09% del total del territorio estatal, el cual asciende a 3,991.14 Km2.El acceso principal al Pueblo Mágico de Ixtenco es a través de carretera federal Hidalgo.", maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15066.893367966968!2d-97.89725265!3d19.250885399999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cffa38785430cd%3A0xea338488942bbd8b!2sIxtenco%2C%20Tlax.!5e0!3m2!1ses-419!2smx!4v1739821777744!5m2!1ses-419!2smx" },
    { history: "El municipio de Huamantla del náhuatl “Lugar de árboles alineados o juntos” fue formado entre los años 1800-1700 al 1,200 a.c. con la colonización de 13 colonias que ascendían aproximadamente a 3,500 personas.Huamantla es un territorio con grandes riquezas naturales y suelo fértil; por ello, el Pueblo Otomí en su asentamiento se dedicó al cultivo de maíz, frijol, chile, tomate, aguacate, así como maguey.", text: "El municipio de Huamantla se encuentra situado en el Altiplano Central Mexicano a 2,500 metros sobre el nivel del mar, cuyas coordenadas son 19° 19’ latitud norte y 97° 55’ longitud oeste. Huamantla colinda al norte con los municipios de Terrenate y Altzayanca, al sur con el municipio de Ixtenco, al oriente con los municipios de Cuapiaxtla y Altzayanca, y al poniente con los municipios de Xaloztoc, San José Teacalco, Tetlanohcan, Totcatlán y Tzompantepec.", maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30121.07420734968!2d-97.92844584999999!3d19.319978799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cffb8a3dc6391d%3A0x4c4af94003197a37!2sHuamantla%2C%20Tlax.!5e0!3m2!1ses-419!2smx!4v1739821716840!5m2!1ses-419!2smx" }
  ];
  const gastronomy = [
    { img1: "tlaxco/Tlaxco1.jpg", img2: "tlaxco/Tlaxco2.jpg", img3: "tlaxco/Tlaxco3.jpg", img4: "tlaxco/Tlaxco4.jpg", title: "Gastronomía de Tlaxco, Tlaxcala", text: "Los platillos típicos del Tlaxco son chamorro al pulque, escamoles, chinicuiles y gusanos de maguey, además de quesos artesanales, requesón horneado, el pulque y los curados de esta bebida ancestral. Adicionalmente, puedes saborear el queso de puerco, el queso de chicharrón envuelto en empaque de palma, tlacoyitos de alverjón, sopa de milpa y quesos asados con salsa de pasilla. Algunos de sus platillos más representativos son el delicioso mole negro de huitlacoche, el chamorro al pulque y el huaxmole de chito que es carne de chivo o carnero seca y curada con sal mezclado con chile guajillo y otros ingredientes cocido a la leña. Como en todo Tlaxcala su gastronomía tiene una clara influencia prehispánica que se refleja en el uso de algunos insectos como los chinicuiles que se acompañan de salsa y tortillas." },
    { img1: "ixtenco/Ixtenco1.jpg", img2: "ixtenco/Ixtenco2.jpg", img3: "ixtenco/Ixtenco3.jpg", img4: "ixtenco/Ixtenco4.jpg", title: "Gastronomía de Ixtenco, Tlaxcala", text: "Los platillos regionales de Ixtenco son: el mole de guajolote, pipían rojo, queso, barbacoa de carnero y gusanos de maguey en su jugo. Los dulces en conservas de frutas, calabaza con piloncillo, atole de masa y dulces de pera, tejocote, capulín y durazno y la bebida típica es el pulque natural. Las diversas variedades de maíz de este pueblo son todo un atractivo. Se siembran más de 35 especies de semillas que ofrecen todo un espectáculo multicolor, ya que hay maíces azules, rosas, morados, negros, rojos carmín y otros más. Dentro de sus platillos típicos se encuentran: mole de ladrillo, hecho a base de chile guajillo, barbacoa de carnero, gusanos de maguey en su jugo, el chileatole y el panqué de elote. Dentro de las bebidas, debes probar el atole agrio elaborado a base de maíz morado." },
    { img1: "huamantla/Huamantla1.jpg", img2: "huamantla/Huamantla2.jpg", img3: "huamantla/Huamantla3.jpg", img4: "huamantla/Huamantla4.jpg", title: "Gastronomía de Huamantla, Tlaxcala", text: "Los platillos típicos de Huamantla son mixiotes de carnero, barbacoa de borrego, tlacoyos de haba en forma de triángulo, muéganos y preparaciones a base de hongos silvestres. Otros de sus platillos más representativos son: el mole de matuma o mole de ladrillo que es un mole espeso que lleva carne de res, chile guajillo, clavo, canela y semillas de cilantro, y se sirve con tamales azules o morados. También puedes probar el chicharrón en salsa de tecol y los escamoles a la mantequilla. Los escamoles son huevecillos de las hormigas negras y rojas que se sacan de debajo de los magueyes. En sus bebidas el pulque es básico y lo hay de diferentes sabores que se mezclan con frutas, los cuales son conocidos como curados." }
  ];
  const getImagePath = (img) => {
    return require(`../../assets/img/pueblos-magicos/gastronomia/${img}`);
  };
  const getImageCardPath = (img) => {
    return require(`../../assets/img/pueblos-magicos/${img}`);
  };
  return (
    <>
      <div className="svg-container">
        <CloudBG />
      </div>
      <div class="card-container">
        {cardData.map((card, index) => (
          <div class="card" key={index}>
            <LazyLoadImage class="card-img" src={getImageCardPath(card.img)} alt='' />
            <div class="card-body">
              <h5 class="card-title">{card.title}</h5>
              <p class="card-text">{card.text}</p>
              <a href="#info" class="card-button" onClick={() => handleShowDetails(index)}>VER MÁS</a>
            </div>
          </div>
        ))}
      </div>
      {showDetails && (
        <>
          <div className="info-container" id="info">
            <div className="content-pm">
              <div>
                <h1 className="informacion--titulo">UBICACION</h1>
                <p>{location[selectedIndex].text}</p>
              </div>
              <div className="map-container">
                <iframe title={`map-${selectedIndex}`} src={location[selectedIndex].maps} width="100%" height="450"
                allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <div className="content-right">
                <p>{location[selectedIndex].history}</p>
              </div>
            </div>
          </div>
          <div className="food-container">
            <div className="content-pm">
              <div className="content-left">
                <h1 className="informacion--titulo">{gastronomy[selectedIndex].title}</h1>
                <LazyLoadImage
                  src={getImagePath(gastronomy[selectedIndex].img1)}
                  alt="Card Title"
                  className="content-img"
                />
              </div>
              <div >
                <p>{gastronomy[selectedIndex].text}</p>
                <div className="image-grid">
                  <LazyLoadImage
                    src={getImagePath(gastronomy[selectedIndex].img2)}
                    alt="Card Title"
                    className="content-img"
                  />
                  <LazyLoadImage
                    src={getImagePath(gastronomy[selectedIndex].img3)}
                    alt="Card Title"
                    className="content-img"
                  />
                </div>
              </div>
              <div className="image-right">
                <LazyLoadImage
                  src={getImagePath(gastronomy[selectedIndex].img4)}
                  alt="Card Title"
                  className="content-img"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NotFound;
